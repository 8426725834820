import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import SaltLakeRealtorImage from '../../assets/images/publication-banners/SL-Realtor.jpg';

const SaltLakeRealtor = (props) => (
  <Layout>
    <Helmet>
      <title>Salt Lake REALTOR® - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and issue information for the Salt Lake REALTOR® magazine. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Salt Lake REALTOR®</h1>
          </header>
          <span className="image main">
            <img src={SaltLakeRealtorImage} alt="" />
          </span>
          <p className="box">
            The Salt Lake REALTOR® is the official publication for the Salt Lake
            Board of REALTORS.® The magazine is published monthly and is
            distributed to all licensed REALTORS® in the Salt Lake Valley, as
            well as various real estate related businesses in the area. The
            purpose of the magazine is to inform REALTORS® and their associates
            of current marketing happenings, encourage and support affirmative
            action in the local marketplace, and provide education about trends
            in real estate.
          </p>
          <ul className="actions">
            <li>
              <a href="https://slrealtors.com/" className="button special">
                <i className="icon fa-globe"> </i>Salt Lake REALTOR® Website
              </a>
            </li>
          </ul>
          <h2>Salt Lake REALTOR® {new Date().getFullYear()} Information</h2>

          <div className="box">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Issue</th>
                    <th>Editorial Theme</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>January</td>
                    <td>
                      Meet 2022 Salt Lake Board President Steve Perry 2022 Salt
                      Lake Housing Forecast Report
                    </td>
                  </tr>
                  <tr>
                    <td>February</td>
                    <td>Realtor 500 Awards</td>
                  </tr>
                  <tr>
                    <td>March</td>
                    <td>RPAC Top Investors issue</td>
                  </tr>
                  <tr>
                    <td>April</td>
                    <td>Drought and housing. Do we have enough water?</td>
                  </tr>
                  <tr>
                    <td>May</td>
                    <td>First quarter Wasatch Front home sales</td>
                  </tr>
                  <tr>
                    <td>June</td>
                    <td>New home prices and starts</td>
                  </tr>
                  <tr>
                    <td>July</td>
                    <td>Parade of Homes</td>
                  </tr>
                  <tr>
                    <td>August</td>
                    <td>
                      Salt Lake home sales/prices first six months of 2022
                    </td>
                  </tr>
                  <tr>
                    <td>September</td>
                    <td>Still a seller’s market?</td>
                  </tr>
                  <tr>
                    <td>October</td>
                    <td>Professionalism and the Code of Ethics</td>
                  </tr>
                  <tr>
                    <td>November</td>
                    <td>Third quarter Wasatch Front home sales</td>
                  </tr>
                  <tr>
                    <td>December</td>
                    <td>Realtor Service Awards issue</td>
                  </tr>
                </tbody>
              </table>
              <em>Editorial subject to change</em>
              <section className="box">
                <strong>Circulation</strong>: 10,000 per issue average.
                <br />
                <strong>File Ready</strong>: 25th of the month prior to
                publication/issue.
                <br />
                <em>
                  Note: All dates falling on weekends will be moved to the
                  following Monday. All Holidays (November, December) please
                  have ads in, on week prior.
                </em>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default SaltLakeRealtor;
